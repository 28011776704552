.rdrInRange {
    background-color: rgba(73, 106, 112, 0.12);
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
    color: inherit;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrInRange {
    border-radius: 0;
}

.rdrStartEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.rdrEndEdge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.rdrDayToday .rdrDayNumber span:after{
    background: #496A70;
}

.rdrDay {
    height: 4em;
    line-height: 4em;
    color: #000000;
}

.rdrStaticRange {
    border-bottom: none;
}


.rdrStaticRangeLabel {
    text-align: center;
}

.rdrDefinedRangesWrapper {
    padding-top: 10px;
    width: 180px;
}